<template>
  <div>
    <template v-if="! loading">
      <h4>Edit User Information</h4>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-md-6 mb-2">
                <label>Name</label>
                <input type="text" placeholder="Name" v-model="user.name" required class="form-control">
              </div>
              <div class="col-md-6 mb-2">
                <label>Email</label>
                <input type="text" placeholder="Email" v-model="user.email" required class="form-control mb-2">
                <input type="checkbox" v-model="user.email_verified" id="email-verified" value="1">
                <label for="email-verified" class="ms-3">Mark email as verified</label>
              </div>
              <div class="col-md-6 mb-2">
                <label>Phone Number</label>
                <input type="text" placeholder="Phone Number" v-model="user.phone_number" required class="form-control mb-2">
                <input type="checkbox" v-model="user.phone_number_verified" value="1" id="phone-verified">
                <label for="phone-verified" class="ms-3">Mark phone as verified</label>
              </div>
              <div class="col-md-6 mb-2">
                <label>Role</label>
                <select v-model="user.role" class="form-control">
                  <option value="customer">Customer</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div class="col-md-12 mt-4">
                <button class="btn btn-primary"><i class="lni lni-save me-2"></i>Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      user: {},
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true
      this.axios.get(`/api/v1/admin/users/${this.$route.params.id}`).then(response => {
        this.user = response.data.user
        this.loading = false
      })
    },

    submit() {
      this.loading = true
      this.user.profile = 1

      this.axios.put(`/api/v1/admin/users/${this.$route.params.id}`, this.user).then(() => {
        this.loading = false
        this.$router.push({ name: 'admin.users.show' })
      })
    }
  }
}
</script>